@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@100..900&display=swap');



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Noto Sans Georgian", sans-serif;
}

body {
  background-color: #f5f5f5;
  width: 100vw;
  overflow-x: hidden;
}

.open {
  animation: opening 0.5s ease-in;
}

.close {
  animation: closing 0.5s ease-in;
}

@keyframes opening {
  0% {
    right: -20%;
  }

  100% {
    right: 0%;
  }
}

@keyframes closing {
  0% {
    right: 0%;
  }

  100% {
    right: -40%;
  }
}


@media(max-width:400px) {
  header {
    justify-content: space-between !important;
  }


}

.show {
  padding: 12px;
  opacity: 1;
  height: auto;
  transition: opacity 0.5s ease, height 0.5s ease;
}

.hide {
  padding: 0;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.5s ease, height 0.5s ease;

}


.active {
  color: #4682b4;
  text-decoration: underline;
  text-underline-offset: 8px;

}


.t {
  top:13px;
}



.lb {
  position: absolute;
  top: 0;
  transform: translateY(-55%);
  z-index: 2;
  background-color: white;
}

.lm {
  position: absolute;
  top: 0;
  right: 25px;
  transform: translateY(-55%);
  z-index: 2;
  background-color: white;
}


.b {
  bottom: -20px;
}

.bb{
  bottom: -30px;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;

}




.fill {
  position: absolute;
  top: 0;
  left: 10.5px;
  border-radius: 2px;
  width: 3px;
  height: 100%;
  /* Ensures the div covers the entire height */
  background-color: #9f9e9e;
  /* Gray background color */
  overflow: hidden;
  /* Hides overflowing green color */

}

.fill::after {
  content: "";
  position: absolute;
  border-radius: 2px;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--filledPercentage, 0%);
  background-color: rgb(34 197 94);
  /* Green fill color */
  transition: height 0.5s ease;
  /* Smooth transition for height change */
}


.title {
  position: relative;

}


.title::before {
  width: 100%;
  height: 1px;
  background-color: #9f9e9e;
  border-radius: 1px;
  position: absolute;
  content: '';
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.title2 {
  position: relative;

}


.title2::before {
  width: 97%;
  height: 1px;
  background-color: #9f9e9e;
  border-radius: 1px;
  position: absolute;
  content: '';
  top: 50%;
  transform: translateY(-50%);

}





/* .gradient-bg {
  background: linear-gradient(95deg,
      #EF4444 0%,
    
      #FBBF24 25%,
   
      rgb(244, 233, 25)50%,
 
      rgba(157, 236, 37, 0.994) 75%,

      rgb(111, 186, 230) 100%
    
    );
  -webkit-background-clip: text;

  background-clip: text;
  color: transparent;
} */



@media (max-width: 639px) {
  .title::before {
    display: none;
  }
}



.imageheight {
  height: 400px;
}



@media(max-width:340px) {
  .custom-padding {
    padding: 8px;
  }
}


@media(max-width:400px) {
  .custom-padding {
    padding: 12px;
  }
}

@media(max-width:470px) {
  .custom-padding {
    padding: 20px;
  }
}

@media(max-width:500px) {
  .custom-padding {
    padding: 30px;
  }
}

@media(max-width:550px) {
  .custom-padding {
    padding: 50px;
  }
}

@media(max-width:639px) {
  .custom-padding {
    padding: 65px;
  }
}



@media(max-width:458px) {
  .custom-padding2 {
    padding: 10px;
  }
}



.slick-prev, .slick-next {
  display: none !important
}


.left {
  left: -5px;
}

.right {
  right: -5px;
}


.fade-in {
  animation: fade-in 0.5s ease;
  transition: all 0.5s ease;
}

.fade-out {
  animation: fade-out 0.5s ease;
  transition: all 0.3s ease;
}


@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}


.poligon {
  clip-path: polygon(0% 0%, 0% 82.5%, 1.69492% 84.3293%, 3.38983% 86.1385%, 5.08475% 87.9078%, 6.77966% 89.6179%, 8.47458% 91.25%, 10.1695% 92.7862%, 11.8644% 94.2098%, 13.5593% 95.505%, 15.2542% 96.6578%, 16.9492% 97.6555%, 18.6441% 98.487%, 20.339% 99.1435%, 22.0339% 99.6176%, 23.7288% 99.9041%, 25.4237% 100%, 27.1186% 99.9041%, 28.8136% 99.6176%, 30.5085% 99.1435%, 32.2034% 98.487%, 33.8983% 97.6555%, 35.5932% 96.6578%, 37.2881% 95.505%, 38.9831% 94.2098%, 40.678% 92.7862%, 42.3729% 91.25%, 44.0678% 89.6179%, 45.7627% 87.9078%, 47.4576% 86.1385%, 49.1525% 84.3293%, 50.8475% 82.5%, 52.5424% 80.6708%, 54.2373% 78.8616%, 55.9322% 77.0922%, 57.6271% 75.3821%, 59.322% 73.75%, 61.017% 72.2138%, 62.7119% 70.7902%, 64.4068% 69.495%, 66.1017% 68.3422%, 67.7966% 67.3446%, 69.4915% 66.513%, 71.1864% 65.8565%, 72.8814% 65.3824%, 74.5763% 65.0959%, 76.2712% 65%, 77.9661% 65.0959%, 79.661% 65.3824%, 81.3559% 65.8565%, 83.0509% 66.513%, 84.7458% 67.3446%, 86.4407% 68.3422%, 88.1356% 69.495%, 89.8305% 70.7902%, 91.5254% 72.2138%, 93.2203% 73.75%, 94.9153% 75.3821%, 96.6102% 77.0922%, 98.3051% 78.8616%, 100% 80.6708%, 100% 0%);
  shape-outside: polygon(0% 0%, 0% 82.5%, 1.69492% 84.3293%, 3.38983% 86.1385%, 5.08475% 87.9078%, 6.77966% 89.6179%, 8.47458% 91.25%, 10.1695% 92.7862%, 11.8644% 94.2098%, 13.5593% 95.505%, 15.2542% 96.6578%, 16.9492% 97.6555%, 18.6441% 98.487%, 20.339% 99.1435%, 22.0339% 99.6176%, 23.7288% 99.9041%, 25.4237% 100%, 27.1186% 99.9041%, 28.8136% 99.6176%, 30.5085% 99.1435%, 32.2034% 98.487%, 33.8983% 97.6555%, 35.5932% 96.6578%, 37.2881% 95.505%, 38.9831% 94.2098%, 40.678% 92.7862%, 42.3729% 91.25%, 44.0678% 89.6179%, 45.7627% 87.9078%, 47.4576% 86.1385%, 49.1525% 84.3293%, 50.8475% 82.5%, 52.5424% 80.6708%, 54.2373% 78.8616%, 55.9322% 77.0922%, 57.6271% 75.3821%, 59.322% 73.75%, 61.017% 72.2138%, 62.7119% 70.7902%, 64.4068% 69.495%, 66.1017% 68.3422%, 67.7966% 67.3446%, 69.4915% 66.513%, 71.1864% 65.8565%, 72.8814% 65.3824%, 74.5763% 65.0959%, 76.2712% 65%, 77.9661% 65.0959%, 79.661% 65.3824%, 81.3559% 65.8565%, 83.0509% 66.513%, 84.7458% 67.3446%, 86.4407% 68.3422%, 88.1356% 69.495%, 89.8305% 70.7902%, 91.5254% 72.2138%, 93.2203% 73.75%, 94.9153% 75.3821%, 96.6102% 77.0922%, 98.3051% 78.8616%, 100% 80.6708%, 100% 0%);
  margin-bottom: 200px;


}


.gr {
  background-image: linear-gradient(to bottom, #4682b4, #22435e);
}

.gt {
  background-image: linear-gradient(to top, #4682b4, #22435e);
}